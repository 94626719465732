import React, {useEffect, useCallback} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
  Grid,
  GridCol,
  Button,
  Select,
  SelectOption,
  NavHorizontal,
  PageContainer,
  NavItem,
  Checkbox,
  Divider
} from '@flixbus/honeycomb-react';
import {
  Icon,
  IconDelete,
  IconDeleteSolid,
  IconOfferSolid
} from '@flixbus/honeycomb-icons-react';
import ContentLoader from '../../Common/ContentLoader';
import {isNil} from 'ramda';
import {getCountries} from '../../Countries/store/thunks';
import {setCountry} from '../../Countries/store/slice';
import {
  addCountryCostEntryYear,
  deleteCountryCostEntryYear,
  fetchCountryCostYearsById
} from '../store/thunks';
import {
  selectCountry,
  selectYear,
  setCountryDriverConceptType
} from '../store/slice';
import CountryPanel from '../../Countries/components/CountryPanel';
import {NavLink} from 'react-router-dom';
import navHorizontalOptions from '../constants/navHorizontalOptions';
import {RegionalCostsPath} from '../constants/routePaths';
import {isNilOrEmpty} from 'ramda-adjunct';
import {confirm} from '../../Common/Confirm';
import {useToast} from '../../Common/toasts-container';
import {getCountryName} from '../../Home/utils';
import {useUpdateCountryQualityCheck} from '../hooks/useUpdateCountryQualityCheck';
import PowerBiReportLink from 'components/PowerBiReportLink/PowerBiReportLink';
import {POWER_BI_REPORT_REGIONAL_YOY_URL} from 'constants/PowerBiReportUrls';

const RegionalCosts = ({
  country,
  countries,
  getCountries,
  selectCountry,
  fetchCountryCostYearsById,
  year,
  years,
  setCountry,
  selectYear,
  addCountryCostEntryYear,
  setCountryDriverConceptType
}) => {
  const dispatch = useDispatch();
  const {addToast} = useToast();
  const location = useLocation();
  const regionalCosts = useSelector(state => state?.regionalCosts);

  useEffect(() => {
    if (isNil(countries)) {
      getCountries();
    }
  }, [countries, getCountries]);

  const ignoreQualityCheckValidationConfirmation = useCallback(
    () =>
      confirm({
        title: 'Some mandatory fields are empty',
        message: 'Please go back and specify all the values',
        acceptBtnText: 'Proceed anyway',
        cancelBtnText: 'Go back'
      }),
    []
  );
  const {updateQualityCheck} = useUpdateCountryQualityCheck(
    ignoreQualityCheckValidationConfirmation
  );

  if (isNil(countries)) {
    return <ContentLoader />;
  }

  const countriesOptions = countries.map(country => (
    <SelectOption key={country.id} value={country.id}>
      {country.name}
    </SelectOption>
  ));

  const yearsOptions = years.map(year => (
    <SelectOption key={year} value={year}>
      {year}
    </SelectOption>
  ));

  const onDeleteCountryCostEntryYear = async () => {
    const countryName = getCountryName(countries, country);
    if (
      await confirm({
        PopupIcon: IconDelete,
        title: `You are about to delete all cost data for country ${countryName} and year ${year}`,
        message: `Do you wish to proceed ?`
      })
    ) {
      dispatch(deleteCountryCostEntryYear(year, addToast));
    }
  };

  const isYearSelected =
    (location.pathname === RegionalCostsPath.vehicleCosts ||
      location.pathname === RegionalCostsPath.otherCosts ||
      location.pathname === RegionalCostsPath.driverCosts) &&
    regionalCosts?.year;

  return (
    <PageContainer>
      <div className={`header-section`}>
        <Grid>
          <GridCol size={3}>
            <div className={`regional-costs-countries`}>
              <div>
                <Select
                  id="regional-costs-select-country"
                  label="Country"
                  value={isNil(country) ? '' : country}
                  placeholder="Select a Country"
                  onChange={e => {
                    selectCountry(e.target.value);
                    fetchCountryCostYearsById(e.target.value);
                  }}
                >
                  {countriesOptions}
                </Select>
              </div>
              <div>
                <Button
                  display="square"
                  aria-label="New country"
                  onClick={() => setCountry()}
                >
                  <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
                </Button>
              </div>
            </div>
          </GridCol>
          {location.pathname === RegionalCostsPath.vehicleCosts ||
          location.pathname === RegionalCostsPath.otherCosts ||
          location.pathname === RegionalCostsPath.driverCosts ? (
            <>
              <GridCol size={3}>
                <div className={`regional-costs-years`}>
                  <div>
                    <Select
                      disabled={isNil(country)}
                      id="regional-costs-years"
                      label="Calendar year"
                      value={isNil(year) ? '' : year}
                      placeholder="Select a year"
                      onChange={e => selectYear(Number(e.target.value))}
                    >
                      {yearsOptions}
                    </Select>
                  </div>
                  <div>
                    <Button
                      display="square"
                      aria-label="New year"
                      disabled={isNil(country)}
                      onClick={addCountryCostEntryYear}
                    >
                      <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
                    </Button>
                    <Button
                      display="square"
                      aria-label="Delete year"
                      disabled={isNil(country) || isNilOrEmpty(year)}
                      onClick={onDeleteCountryCostEntryYear}
                    >
                      <Icon InlineIcon={IconDeleteSolid} aria-hidden="true" />
                    </Button>
                  </div>
                </div>
              </GridCol>
            </>
          ) : null}
        </Grid>

        <div className={`hcr-space-4-top`}>
          <Grid align="center" extraClasses={isYearSelected ? '' : 'hidden'}>
            <GridCol key="grid-nested-checkbox" size={8}>
              <Checkbox
                id="regional-costs-year-quality-check"
                checked={regionalCosts?.qualityChecked}
                value={regionalCosts?.qualityChecked.toString()}
                label={year + ` regional costs database completed`}
                aria-label="regional-costs-year-quality-check"
                onChange={event => updateQualityCheck(event.target.checked)}
              />
            </GridCol>
            <GridCol
              key="grid-nested-link"
              size={4}
              extraClasses={'justify-end'}
            >
              <PowerBiReportLink
                href={POWER_BI_REPORT_REGIONAL_YOY_URL}
                label="Analyse YoY development"
              />
            </GridCol>
          </Grid>
        </div>

        <Grid>
          <GridCol size={12}>
            <div className={`hcr-space-4-top nav-horizontal-container`}>
              {location.pathname === RegionalCostsPath.driverConceptTypes ? (
                <div
                  className={`nav-horizontal-button hcr-has-text-right hcr-space-2-bottom`}
                >
                  <Button
                    disabled={isNil(country)}
                    onClick={() => setCountryDriverConceptType()}
                  >
                    <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
                    Add concept
                  </Button>
                </div>
              ) : null}

              <NavHorizontal
                extraClasses={`regional-costs-nav`}
                aria-label="Regional Costs Navigation"
              >
                {navHorizontalOptions.map(item => (
                  <NavItem
                    key={item.path}
                    to={item.path}
                    RouterLink={NavLink}
                    end
                  >
                    {item.name}
                  </NavItem>
                ))}
              </NavHorizontal>
            </div>
          </GridCol>
        </Grid>
      </div>

      <Divider extraClasses={'full-width-divider'} />

      <div className="hcr-space-4-top">
        <Outlet />
      </div>

      <CountryPanel />
    </PageContainer>
  );
};

export default connect(
  state => ({
    countries: state.countries.list,
    country: state.regionalCosts.country,
    year: state.regionalCosts.year,
    years: state.regionalCosts.years
  }),
  {
    getCountries,
    selectCountry,
    selectYear,
    fetchCountryCostYearsById,
    setCountry,
    addCountryCostEntryYear,
    setCountryDriverConceptType
  }
)(RegionalCosts);

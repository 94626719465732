import React, {useEffect, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Grid,
  GridCol,
  Button,
  DataTable,
  DataTableHead,
  DataTableRow,
  PageContainer
} from '@flixbus/honeycomb-react';
import {
  IconOfferSolid,
  IconEditSolid,
  IconDeleteSolid,
  IconDelete,
  Icon
} from '@flixbus/honeycomb-icons-react';
import ContentLoader from '../../Common/ContentLoader';
import {isNil} from 'ramda';
import {confirm} from '../../Common/Confirm';
import {setCountry} from '../store/slice';
import {deleteCountry, getCountries} from '../store/thunks';
import CountryPanel from './CountryPanel';
import {useToast} from '../../Common/toasts-container';

export const COUNTRY_CURRENCY_FEATURE_ENABLED = true;

const CountriesPage = () => {
  const countries = useSelector(state => state?.countries.list);
  const dispatch = useDispatch();
  const {addToast} = useToast();

  useEffect(() => {
    if (isNil(countries)) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  const deleteCountryAction = useCallback(
    async country => {
      if (
        await confirm({
          PopupIcon: IconDelete,
          title:
            'This action will also delete empty driving defaults and the driver salary periods defined. Delete this country?',
          message: `${country.name}`
        })
      ) {
        dispatch(deleteCountry(country, addToast));
      }
    },
    [dispatch, addToast]
  );

  const headers = useMemo(
    () => (
      <DataTableHead>
        <th scope="col">Country</th>
        <th scope="col">Business region</th>
        <th scope="col">
          {COUNTRY_CURRENCY_FEATURE_ENABLED ? 'Currency' : ''}
        </th>
        <th scope="col"></th>
      </DataTableHead>
    ),
    []
  );

  const rows = useMemo(
    () =>
      (countries ?? []).map(country => ({
        cells: [
          country.name,
          country.businessRegion,
          COUNTRY_CURRENCY_FEATURE_ENABLED ? country?.currency : '',
          <>
            <Button
              display="square"
              aria-label="Edit Country"
              onClick={() =>
                dispatch(setCountry({...country, countryBeforeEdit: country}))
              }
            >
              <Icon InlineIcon={IconEditSolid} aria-hidden="true" />
            </Button>
            <Button
              display="square"
              aria-label="Delete Country"
              appearance={`danger`}
              onClick={() => deleteCountryAction(country)}
            >
              <Icon InlineIcon={IconDeleteSolid} aria-hidden="true" />
            </Button>
          </>
        ]
      })),
    [countries, deleteCountryAction, dispatch]
  );

  if (isNil(countries)) {
    return <ContentLoader />;
  }

  return (
    <PageContainer>
      <div className={`hcr-has-text-right hcr-space-4-top hcr-space-2-bottom`}>
        <Button onClick={() => dispatch(setCountry())}>
          <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
          Add country
        </Button>
      </div>
      <Grid>
        <GridCol size={12}>
          <DataTable headers={headers}>
            {rows.map(row => (
              <DataTableRow key={row.cells[0]}>
                {row.cells.map((cell, index) => (
                  <td key={index}>{cell}</td>
                ))}
              </DataTableRow>
            ))}
          </DataTable>
        </GridCol>
      </Grid>
      <CountryPanel />
    </PageContainer>
  );
};

export default React.memo(CountriesPage);

import {
  Button,
  Heading,
  Popup,
  PopupSection,
  Text
} from '@flixbus/honeycomb-react';
import {Icon, IconDelete} from '@flixbus/honeycomb-icons-react';
import {isNil} from 'ramda';
import React from 'react';
import {useDispatch} from 'react-redux';
import {deleteProductionCostEntry} from '../store/thunks';

const DeleteConfirmationPopup = ({setEntryToDelete, entryToDelete}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteProductionCostEntry(entryToDelete.id));
    setEntryToDelete(null);
  };

  return (
    <Popup
      id="delete-entry-popup"
      active={!isNil(entryToDelete)}
      onOverlayClick={() => setEntryToDelete(null)}
    >
      <PopupSection type="icon">
        <Icon size={6} style={{fill: 'black'}} InlineIcon={IconDelete} />
      </PopupSection>
      <PopupSection type="title">
        <Heading size={2}>Delete this bundle?</Heading>
        {entryToDelete && <Text>{entryToDelete?.name}</Text>}
      </PopupSection>
      <PopupSection type="actions">
        <div style={{display: 'flex', gap: 10}}>
          <Button appearance="tertiary" onClick={() => setEntryToDelete(null)}>
            No, don&lsquo;t delete
          </Button>
          <Button appearance={'primary'} onClick={handleDelete}>
            Yes, delete
          </Button>
        </div>
      </PopupSection>
    </Popup>
  );
};

export default React.memo(DeleteConfirmationPopup);

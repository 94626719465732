import graphqlClient from '../../../services/graphqlClient';
import getCountryCostEntryYearsQuery from '../graphql/getCountryCostEntryYearsQuery';
import createCountryCostEntryYearMutation from '../graphql/createCountryCostEntryYearMutation';
import deleteCountryCostEntryYearMutation from '../graphql/deleteCountryCostEntryYearMutation';
import {isNil} from 'ramda';
import getCountryCostEntryQuery from '../graphql/getCountryCostEntryQuery';
import updateCountryBusCostEntryMutation from '../graphql/updateCountryBusCostEntryMutation';
import createCountryBusTypeCostEntryMutation from '../graphql/createCountryBusTypeCostEntryMutation';
import updateCountryBusTypeCostEntryMutation from '../graphql/updateCountryBusTypeCostEntryMutation';
import deleteCountryBusTypeCostEntryMutation from '../graphql/deleteCountryBusTypeCostEntryMutation';
import getCountryPrefilledEmptyValuesQuery from '../graphql/getCountryPrefilledEmptyValuesQuery';
import saveCountryPrefilledEmptyValuesMutation from '../graphql/saveCountryPrefilledEmptyValuesMutation';
import getCountryDriverConceptTypesQuery from '../graphql/getCountryDriverConceptTypesQuery';
import {pick} from 'ramda';
import deleteCountryDriverConceptTypeMutation from '../graphql/deleteCountryDriverConceptTypeMutation';
import createCountryDriverConceptTypeMutation from '../graphql/createCountryDriverConceptTypeMutation';
import updateCountryDriverConceptTypeMutation from '../graphql/updateCountryDriverConceptTypeMutation';
import getCountrySalaryDefinitionsQuery from '../graphql/getCountrySalaryDefinitionsQuery';
import saveCountrySalaryDefinitionsMutation from '../graphql/saveCountrySalaryDefinitionsMutation';
import {displayErrorMessage, displaySuccessMessage} from '../../Common/Utils';
import updateCountryOtherCostEntryMutation from '../graphql/updateCountryOtherCostEntryMutation';
import updateCountryDriverCostEntryMutation from '../graphql/updateCountryDriverCostEntryMutation';
import {
  addToCountryVehicleTypeCostAction,
  updateVehicleTypeCostAction,
  deleteFromVehicleTypeCostAction,
  receivedCountryOtherCost,
  receivedCountryDriverCost,
  receivedCountryCostYears,
  receivedcountryCostEntry,
  addedNewYear,
  deletedNewYear,
  receivedCountryVehicleCostAction,
  receivedCountryPrefilledEmptyValues,
  receivedCountryDriverConceptTypes,
  deleteFromCountryDriverConceptTypes,
  addToCountryDriverConceptTypes,
  resetCountryDriverConceptType,
  updateCountryDriverConceptTypes,
  receivedCountrySalaryDefinitions,
  driverSalaryDefinitionsSaved,
  driverSalaryDefinitionsErrors
} from './slice';

export const createCountryVehicleTypeCost = (
  vehicleTypeCost,
  countryId,
  year,
  setCustomIdentifierErrorMessage
) => {
  return dispatch => {
    if (isNil(countryId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: createCountryBusTypeCostEntryMutation,
        variables: {
          countryId,
          year,
          busTypeId: vehicleTypeCost.busTypeId,
          customIdentifier: vehicleTypeCost.customIdentifier,
          insuranceCost: vehicleTypeCost.insuranceCost,
          interestCost: vehicleTypeCost.interestCost,
          leasingCost: vehicleTypeCost.leasingCost,
          maintenanceCostPerMonth: vehicleTypeCost.maintenanceCostPerMonth,
          maintenanceCostPerDistanceUnit:
            vehicleTypeCost.maintenanceCostPerDistanceUnit,
          motorVehicleTax: vehicleTypeCost.motorVehicleTax,
          vehicleProductionYear: vehicleTypeCost.vehicleProductionYear
        },
        errorPolicy: 'all'
      })
      .then(result => {
        let {
          data: {createCountryBusTypeCostEntry},
          errors
        } = result;
        if (errors) {
          setCustomIdentifierErrorMessage(
            errors[0]?.extensions?.errorMessage || errors[0].message
          );
        } else {
          setCustomIdentifierErrorMessage(null);
          dispatch(
            addToCountryVehicleTypeCostAction(createCountryBusTypeCostEntry)
          );
        }
      });
  };
};

export const updateCountryVehicleTypeCost = (
  vehicleTypeCost,
  countryId,
  year,
  setCustomIdentifierErrorMessage
) => {
  return dispatch => {
    if (isNil(countryId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: updateCountryBusTypeCostEntryMutation,
        variables: vehicleTypeCost,
        errorPolicy: 'all'
      })
      .then(result => {
        let {
          data: {updateCountryBusTypeCostEntry},
          errors
        } = result;
        if (errors) {
          setCustomIdentifierErrorMessage(
            errors[0]?.extensions?.errorMessage || errors[0].message
          );
        } else {
          setCustomIdentifierErrorMessage(null);
          dispatch(updateVehicleTypeCostAction(updateCountryBusTypeCostEntry));
        }
      });
  };
};

export const deleteCountryVehicleTypeCost = (vehicleTypeCostId, addToast) => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: deleteCountryBusTypeCostEntryMutation,
        variables: {id: vehicleTypeCostId},
        errorPolicy: 'all'
      })
      .then(result => {
        let {
          data: {deleteCountryBusTypeCostEntry},
          errors
        } = result;
        if (errors) {
          displayErrorMessage(
            addToast,
            errors[0]?.extensions?.errorMessage || errors[0].message
          );
        } else {
          displaySuccessMessage(
            addToast,
            'Vehicle type cost deleted successfully'
          );
        }
        dispatch(
          deleteFromVehicleTypeCostAction(deleteCountryBusTypeCostEntry)
        );
      });
  };
};

export const updateOtherCost = (otherCost, countryId, year) => {
  return dispatch => {
    if (isNil(countryId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: updateCountryOtherCostEntryMutation,
        variables: {...otherCost, countryId, year}
      })
      .then(result => {
        let {
          data: {updateCountryOtherCostEntry}
        } = result;
        dispatch(receivedCountryOtherCost(updateCountryOtherCostEntry));
      });
  };
};

export const updateDriverCost = (driverCost, countryId, year) => {
  return dispatch => {
    if (isNil(countryId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: updateCountryDriverCostEntryMutation,
        variables: {...driverCost, countryId, year}
      })
      .then(result => {
        let {
          data: {updateCountryDriverCostEntry}
        } = result;
        dispatch(receivedCountryDriverCost(updateCountryDriverCostEntry));
      });
  };
};

export const fetchCountryCostYearsById = countryId => {
  return dispatch => {
    graphqlClient
      .query({
        query: getCountryCostEntryYearsQuery,
        variables: {countryId},
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        let {
          data: {countryCostEntryYears}
        } = result;
        dispatch(receivedCountryCostYears(countryCostEntryYears));
      });
  };
};

export const fetchCountrySpecificCost = (countryId, year) => {
  return dispatch => {
    if (isNil(countryId) || isNil(year)) {
      return;
    }

    graphqlClient
      .query({
        query: getCountryCostEntryQuery,
        variables: {countryId, year},
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        let {
          data: {countryCostEntry}
        } = result;
        dispatch(receivedcountryCostEntry(countryCostEntry));
      });
  };
};

export const addCountryCostEntryYear = () => {
  return (dispatch, getState) => {
    const state = getState();

    const countryId = state.regionalCosts.country;

    if (isNil(countryId)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: createCountryCostEntryYearMutation,
        variables: {countryId}
      })
      .then(result => {
        let {
          data: {createCountryCostEntryYear}
        } = result;
        dispatch(addedNewYear(createCountryCostEntryYear));
      });
  };
};

export const deleteCountryCostEntryYear = (year, addToast) => {
  return (dispatch, getState) => {
    const state = getState();

    const countryId = state.regionalCosts.country;

    if (isNil(countryId)) {
      return;
    }

    if (isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: deleteCountryCostEntryYearMutation,
        variables: {countryId, year}
      })
      .then(result => {
        let {
          data: {deleteCountryCostEntryYear},
          errors
        } = result;
        if (errors) {
          displayErrorMessage(
            addToast,
            errors[0]?.extensions?.errorMessage || errors[0].message
          );
        } else {
          const year = deleteCountryCostEntryYear?.year;
          dispatch(deletedNewYear(deleteCountryCostEntryYear));
          displaySuccessMessage(
            addToast,
            `Country cost year ${year} deleted successfully`
          );
        }
      });
  };
};

export const updateCountryCostEntry = vehicleCost => {
  return (dispatch, getState) => {
    const {regionalCosts} = getState();

    const countryId = regionalCosts.country;
    const year = regionalCosts.year;

    if (isNil(countryId) || isNil(year)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: updateCountryBusCostEntryMutation,
        variables: {...vehicleCost, countryId, year}
      })
      .then(result => {
        let {
          data: {updateCountryBusCostEntry}
        } = result;
        dispatch(receivedCountryVehicleCostAction(updateCountryBusCostEntry));
      });
  };
};

export const fetchCountryPrefilledEmptyValues = () => {
  return (dispatch, getState) => {
    const {regionalCosts} = getState();

    const countryId = regionalCosts.country;

    if (isNil(countryId)) {
      return;
    }

    graphqlClient
      .query({
        query: getCountryPrefilledEmptyValuesQuery,
        variables: {countryId},
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        let {
          data: {countryPrefilledEmptyValues}
        } = result;
        dispatch(
          receivedCountryPrefilledEmptyValues(countryPrefilledEmptyValues)
        );
      });
  };
};

export const updateCountryPrefilledEmptyValues =
  countryPrefilledEmptyValues => {
    return (dispatch, getState) => {
      const {regionalCosts} = getState();

      const countryId = regionalCosts.country;

      if (isNil(countryId)) {
        return;
      }

      graphqlClient
        .mutate({
          mutation: saveCountryPrefilledEmptyValuesMutation,
          variables: {...countryPrefilledEmptyValues, countryId}
        })
        .then(result => {
          let {
            data: {saveCountryPrefilledEmptyValues}
          } = result;
          dispatch(
            receivedCountryPrefilledEmptyValues(saveCountryPrefilledEmptyValues)
          );
        });
    };
  };

export const fetchDriverConceptTypes = () => {
  return (dispatch, getState) => {
    const {regionalCosts} = getState();

    const countryId = regionalCosts.country;

    if (isNil(countryId)) {
      return;
    }

    graphqlClient
      .query({
        query: getCountryDriverConceptTypesQuery,
        variables: {countryId},
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        let {
          data: {countryDriverConceptTypes}
        } = result;

        dispatch(receivedCountryDriverConceptTypes(countryDriverConceptTypes));
      });
  };
};

export const deleteDriverConceptType = (driverConceptType, addToast) => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: deleteCountryDriverConceptTypeMutation,
        variables: pick(['id'], driverConceptType),
        errorPolicy: 'all'
      })
      .then(result => {
        let {
          data: {deleteCountryDriverConceptType},
          errors
        } = result;
        if (errors) {
          addToast({
            type: 'danger',
            content: errors[0]?.extensions?.errorMessage || errors[0].message
          });
        } else {
          addToast({
            type: 'success',
            content: `Driver concept type ${driverConceptType.name} deleted successfully`
          });
        }
        dispatch(
          deleteFromCountryDriverConceptTypes(deleteCountryDriverConceptType)
        );
      });
  };
};

export const saveDriverConceptType = () => {
  return (dispatch, _getState) => {
    const {regionalCosts} = _getState();
    let countryDriverConceptType =
      regionalCosts.countryDriverConceptTypes.countryDriverConceptType;

    const countryId = regionalCosts.country;

    if (isNil(countryId)) {
      return;
    }

    if (isNil(countryDriverConceptType.id)) {
      graphqlClient
        .mutate({
          mutation: createCountryDriverConceptTypeMutation,
          variables: {
            ...pick(
              ['name', 'description', 'multiplicationFactor', 'dailyAllowance'],
              countryDriverConceptType
            ),
            countryId
          }
        })
        .then(result => {
          let {
            data: {createCountryDriverConceptType}
          } = result;

          dispatch(
            addToCountryDriverConceptTypes(createCountryDriverConceptType)
          );
          dispatch(resetCountryDriverConceptType());
        });
    } else {
      graphqlClient
        .mutate({
          mutation: updateCountryDriverConceptTypeMutation,
          variables: pick(
            [
              'id',
              'name',
              'description',
              'multiplicationFactor',
              'dailyAllowance'
            ],
            countryDriverConceptType
          )
        })
        .then(result => {
          let {
            data: {updateCountryDriverConceptType}
          } = result;

          dispatch(
            updateCountryDriverConceptTypes(updateCountryDriverConceptType)
          );
          dispatch(resetCountryDriverConceptType());
        });
    }
  };
};

export const fetchCountrySalaryDefinitions = () => {
  return (dispatch, _getState) => {
    const {regionalCosts} = _getState();
    const countryId = regionalCosts.country;

    if (isNil(countryId)) {
      return;
    }

    graphqlClient
      .query({
        query: getCountrySalaryDefinitionsQuery,
        variables: {countryId},
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        let {
          data: {countrySalaryDefinitions}
        } = result;
        dispatch(receivedCountrySalaryDefinitions(countrySalaryDefinitions));
      });
  };
};

export const saveDriverSalaryDefinitions = () => {
  return (dispatch, _getState) => {
    const {regionalCosts} = _getState();
    let countrySalaryDefinitions = regionalCosts.countrySalaryDefinition.list;

    countrySalaryDefinitions = countrySalaryDefinitions.map(
      countrySalaryDefinition =>
        pick(
          ['salaryType', 'dayOfWeek', 'startTime', 'endTime'],
          countrySalaryDefinition
        )
    );

    const countryId = regionalCosts.country;

    if (isNil(countryId)) {
      return;
    }

    graphqlClient
      .mutate({
        mutation: saveCountrySalaryDefinitionsMutation,
        variables: {countryId, countrySalaryDefinitions}
      })
      .then(result => {
        let {
          data: {saveCountrySalaryDefinitions}
        } = result;

        dispatch(driverSalaryDefinitionsSaved(saveCountrySalaryDefinitions));
      })
      .catch(({graphQLErrors}) => {
        const errors = graphQLErrors.map(error => error.message);

        dispatch(driverSalaryDefinitionsErrors(errors));
      });
  };
};

import {Button} from '@flixbus/honeycomb-react';
import {Icon, IconComponent} from '@flixbus/honeycomb-icons-react';
import React from 'react';

type PropsType = {
  name: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  disabled: boolean;
  buttonIcon: IconComponent;
};

const ActionButton: React.FC<PropsType> = props => {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled}
      appearance={props.disabled ? 'tertiary' : 'secondary'}
    >
      <Icon InlineIcon={props.buttonIcon} aria-hidden="true" />
      <span style={{fontSize: 'smaller'}}>{props.name}</span>
    </Button>
  );
};

export default React.memo(ActionButton);

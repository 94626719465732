import {Nullable} from 'Pages/Common/types';
import {PartnerCostsBusTypeCost} from 'Pages/PartnerCosts/store/types';

export const initVehicleTypeCost: Nullable<
  PartnerCostsBusTypeCost | PartnerCostsBusTypeCost
> = {
  id: null,
  year: null,
  busTypeId: null,
  fuelType: null,
  leasingCost: null,
  interestCost: null,
  insuranceCost: null,
  motorVehicleTax: null,
  maintenanceCostPerMonth: null,
  maintenanceCostPerDistanceUnit: null,
  customIdentifier: null,
  vehicleProductionYear: null
};

import React, {useMemo} from 'react';

import {
  Panel,
  PanelHeader,
  PanelContent,
  PanelFooter,
  PanelFooterColumn,
  Button
} from '@flixbus/honeycomb-react';
import {generateDataTestIdFromText} from 'Pages/Common/Utils';

const CpcPanel = ({title, activeAction, closeAction, saveAction, children}) => {
  const dataTestId = useMemo(
    () => generateDataTestIdFromText(title, 'panel'),
    [title]
  );

  return (
    <Panel
      id={title}
      active={activeAction}
      hasOverlay
      onOverlayClick={closeAction}
      data-testid={dataTestId}
    >
      <PanelHeader
        closeButtonProps={{label: 'Close Panel', onClick: closeAction}}
      >
        {title}
      </PanelHeader>

      <PanelContent> {children} </PanelContent>

      <PanelFooter justify="center">
        <PanelFooterColumn>
          <Button appearance="tertiary" onClick={closeAction}>
            Cancel
          </Button>
          <Button appearance="primary" onClick={saveAction}>
            Save
          </Button>
        </PanelFooterColumn>
      </PanelFooter>
    </Panel>
  );
};

export default CpcPanel;

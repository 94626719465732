import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import {getKmsOrMiles} from 'Pages/Common/LabelUtils';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {DriverChangeDistancePerOperatingDayProps} from './DriverChangeDistancePerOperatingDay.types';
import styles from './DriverChangeDistancePerOperatingDay.module.scss';

const DriverChangeDistancePerOperatingDay: React.FC<
  DriverChangeDistancePerOperatingDayProps
> = ({value, entryId}) => {
  const {localUnits} = useLocalUnitsContext();
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-driver-change-distance-per-operating-day`;

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const replacementKmPerDay =
        validateInputNumber(editedValue, value ? value.toString() : '') ??
        undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {replacementKmPerDay}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="driver-change-distance-per-operating-day"
        label={`Driver change/operating day`}
        value={formattedValue}
        tooltipContent={fieldInfoContent.driverChangeKmsOperatingDay(
          localUnits
        )}
        handleInputChange={handleInputChange}
        inlineLabel={getKmsOrMiles(localUnits)}
      />
    </span>
  );
};

export default React.memo(DriverChangeDistancePerOperatingDay);

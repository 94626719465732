import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import {
  Grid,
  GridCol,
  Button,
  DataTable,
  DataTableHead,
  DataTableSorter,
  DataTableRow,
  PageContainer,
  Divider
} from '@flixbus/honeycomb-react';
import {
  IconOfferSolid,
  IconEditSolid,
  IconDeleteSolid,
  IconDelete,
  Icon
} from '@flixbus/honeycomb-icons-react';
import PartnerFilters from 'Pages/Partners/components/PartnerFilters';
import {getQueryParamsSelector} from '../utils';

import ContentLoader from '../../Common/ContentLoader';
import {isNil} from 'ramda';
import {confirm} from '../../Common/Confirm';
import {setPartner} from '../store/slice';
import {getPartners, deletePartner} from '../store/thunks';
import PartnerPanel from './PartnerPanel';
import {getCountries} from '../../Countries/store/thunks';
import {useToast} from '../../Common/toasts-container';

const PartnersPage = ({
  partners,
  countries,
  filters,
  queryParams,
  getPartners,
  setPartner,
  deletePartner,
  getCountries
}) => {
  const {addToast} = useToast();
  const [sortedPartnerRows, setSortedPartnerRows] = useState([]);
  useEffect(() => {
    getCountries();
  }, [getCountries]);

  // Fetches partners on mount and every time filters change
  useEffect(() => {
    getPartners(queryParams);
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const deletePartnerAction = useCallback(
    async partner => {
      if (
        await confirm({
          PopupIcon: IconDelete,
          title: 'Delete this partner?',
          message: `${partner.name}`
        })
      ) {
        deletePartner(partner, addToast);
      }
    },
    [deletePartner, addToast]
  );

  const getCountryNameById = useCallback(
    id => {
      let country = countries.find(country => country.id === id);
      return !isNil(country) ? country.name : id;
    },
    [countries]
  );

  const sortAlphabetically = useCallback(
    (sorting, propKey) => {
      const sortedItems = [...sortedPartnerRows].sort((partner1, partner2) =>
        partner1[propKey].localeCompare(partner2[propKey])
      );
      setSortedPartnerRows(
        sorting === 'descending' ? sortedItems.reverse() : sortedItems
      );
    },
    [sortedPartnerRows]
  );

  const sortByName = useCallback(
    sorting => sortAlphabetically(sorting, 'name'),
    [sortAlphabetically]
  );

  const sortByCountry = useCallback(
    sorting => sortAlphabetically(sorting, 'country'),
    [sortAlphabetically]
  );

  const headers = useMemo(
    () => (
      <DataTableHead>
        <th scope="col">PlanR Backend ID</th>
        <DataTableSorter
          aria-label="Sort Partners by name"
          onSortingChange={sortByName}
        >
          Partner name
        </DataTableSorter>
        <DataTableSorter
          aria-label="Sort Partners by country"
          onSortingChange={sortByCountry}
        >
          Country
        </DataTableSorter>
        <th scope="col"></th>
      </DataTableHead>
    ),
    [sortByName, sortByCountry]
  );

  useEffect(() => {
    if (!partners) return;
    const sortedPartners = [...partners]
      .sort((partner1, partner2) => partner1.name.localeCompare(partner2.name))
      .map(partner => ({
        backendId: partner.backendId,
        name: partner.name,
        country: getCountryNameById(partner.countryId),
        actions: (
          <>
            <Button
              display="square"
              aria-label="Edit Partner"
              onClick={() => setPartner(partner)}
            >
              <Icon InlineIcon={IconEditSolid} aria-hidden="true" />
            </Button>
            <Button
              display="square"
              aria-label="Delete Partner"
              appearance={`danger`}
              onClick={() => deletePartnerAction(partner)}
            >
              <Icon InlineIcon={IconDeleteSolid} aria-hidden="true" />
            </Button>
          </>
        )
      }));
    setSortedPartnerRows(sortedPartners);
  }, [partners, deletePartnerAction, getCountryNameById, setPartner]);

  if (isNil(partners)) {
    return <ContentLoader />;
  }

  return (
    <PageContainer>
      <div className={`header-section`}>
        <PartnerFilters />
      </div>

      <Divider extraClasses={'full-width-divider'} />

      <div className={`hcr-has-text-right hcr-space-4-top hcr-space-2-bottom`}>
        <Button onClick={() => setPartner()}>
          <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
          Add partner
        </Button>
      </div>
      <Grid>
        <GridCol size={12}>
          <DataTable headers={headers}>
            {sortedPartnerRows.map(row => (
              <DataTableRow key={row.name}>
                <td>{row.backendId}</td>
                <td>{row.name}</td>
                <td>{row.country}</td>
                <td>{row.actions}</td>
              </DataTableRow>
            ))}
          </DataTable>
        </GridCol>
      </Grid>
      <PartnerPanel />
    </PageContainer>
  );
};

export default connect(
  state => ({
    partners: state.partners.list,
    countries: state.countries.list ?? [],
    filters: state.partners.filters,
    queryParams: getQueryParamsSelector(state)
  }),
  {getPartners, setPartner, deletePartner, getCountries}
)(PartnersPage);

import {gql} from '@apollo/client';

const CREATE_COUNTRY_BUS_TYPE_COST = gql`
  mutation createCountryBusTypeCostEntry(
    $countryId: ID!
    $busTypeId: ID!
    $year: Int!
    $customIdentifier: String
    $insuranceCost: Float
    $interestCost: Float
    $leasingCost: Float
    $maintenanceCostPerMonth: Float
    $maintenanceCostPerDistanceUnit: CostPerDistance
    $motorVehicleTax: Float
    $vehicleProductionYear: Int
  ) {
    createCountryBusTypeCostEntry(
      input: {
        countryId: $countryId
        busTypeId: $busTypeId
        year: $year
        customIdentifier: $customIdentifier
        insuranceCost: $insuranceCost
        interestCost: $interestCost
        leasingCost: $leasingCost
        maintenanceCostPerMonth: $maintenanceCostPerMonth
        maintenanceCostPerDistanceUnit: $maintenanceCostPerDistanceUnit
        motorVehicleTax: $motorVehicleTax
        vehicleProductionYear: $vehicleProductionYear
      }
    ) {
      id
      busTypeId
      year
      currency
      customIdentifier
      insuranceCost
      interestCost
      leasingCost
      maintenanceCostPerMonth
      maintenanceCostPerDistanceUnit
      motorVehicleTax
      vehicleProductionYear
    }
  }
`;

export default CREATE_COUNTRY_BUS_TYPE_COST;

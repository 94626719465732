import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {NumberOfDriversProps} from './NumberOfDrivers.types';
import styles from './NumberOfDrivers.module.scss';

const NumberOfDrivers: React.FC<NumberOfDriversProps> = ({
  value,
  entryId,
  errors
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-number-of-drivers`;

  const tooltipContent = useMemo(() => fieldInfoContent.numberOfDrivers, []);

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const numberOfDrivers =
        validateInputNumber(
          editedValue,
          value ? value.toString() : '',
          undefined,
          0
        ) ?? undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {numberOfDrivers}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="number-of-drivers"
        label="Number of drivers*"
        value={formattedValue}
        tooltipContent={tooltipContent}
        isValid={!errors}
        infoError={errors?.optionality ?? errors?.nullable}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(NumberOfDrivers);

import React, {Dispatch, PropsWithChildren, SetStateAction} from 'react';
import {Popup, PopupSection, Heading, Button} from '@flixbus/honeycomb-react';
import {Icon} from '@flixbus/honeycomb-icons-react';
import {IconComponent} from '@flixbus/honeycomb-icons-react/src/components/icons/types';

type PropType = {
  popupActive: boolean;
  setPopupActive: Dispatch<SetStateAction<boolean>>;
  actionButtonName: string;
  actionButtonHandler: () => void;
  closeButtonHandler?: () => void;
  disabled?: boolean;
  headerMessage: string;
  icon?: IconComponent;
  extraClasses?: string;
};

const CpcPopup: React.FC<PropsWithChildren<PropType>> = props => {
  const onCloseAction = () => {
    if (props.closeButtonHandler) props.closeButtonHandler();
    props.setPopupActive(false);
  };

  return (
    <Popup
      aria-labelledby="accessible-popup-title"
      active={props.popupActive}
      extraClasses={props.extraClasses}
    >
      {props.icon && (
        <PopupSection type="icon">
          <Icon
            InlineIcon={props.icon}
            size={6}
            style={{fill: 'var(--hcr-primary-icon-color)'}}
            title={props.headerMessage}
          />
        </PopupSection>
      )}
      <PopupSection type="title">
        <Heading id="accessible-popup-title" size={3}>
          {props.headerMessage}
        </Heading>
      </PopupSection>
      <PopupSection type="content" extraClasses="popup-content">
        {props.children}
      </PopupSection>
      <PopupSection type="actions">
        <Button
          appearance="primary"
          onClick={props.actionButtonHandler}
          disabled={props.disabled}
        >
          {props.actionButtonName}
        </Button>
        <Button
          id="accessible-popup-close"
          appearance="tertiary"
          onClick={onCloseAction}
        >
          Close
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default CpcPopup;

import styles from './RegionalCostsVehicleCostContent.module.scss';
import React, {useCallback, useState} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {
  Icon,
  IconDelete,
  IconDeleteSolid,
  IconEditSolid,
  IconOfferSolid
} from '@flixbus/honeycomb-icons-react';
import {Button} from '@flixbus/honeycomb-react';
import {confirm} from 'Pages/Common/Confirm';
import {useToast} from 'Pages/Common/toasts-container';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import {getScaledNumber} from 'Pages/Common/Utils';
import {Nullable} from 'Pages/Common/types';
import {
  RegionalCostsBusTypeCost,
  RegionalCostsVehicleCost
} from 'Pages/RegionalCosts/store/types';
import {
  deleteCountryVehicleTypeCost,
  updateCountryCostEntry
} from 'Pages/RegionalCosts/store/thunks';
import {Table, TableAction, TableRow} from 'components/Table';
import {TableWithPanel, TableWithPanelAction} from 'components/TableWithPanel';
import {initVehicleTypeCost} from 'components/VehicleCosts/VehicleCosts.data';
import {VehicleCostsPanelState} from 'components/VehicleCosts/VehicleCostsPanel/VehicleCostsPanel.types';
import {getBaseCostsPerVehicleTypeTableConfig} from 'components/VehicleCosts/VehicleCosts.utils';
import RegionalCostsExplanationNotice from 'components/RegionalCostsExplanationNotice/RegionalCostsExplanationNotice';
import RegionalCostsVehicleCostPanel from 'Pages/RegionalCosts/components/RegionalCostsVehicleCostTab/RegionalCostsVehicleCostPanel/RegionalCostsVehicleCostPanel';
import {RegionalCostsVehicleCostContentProps} from './RegionalCostsVehicleCostContent.types';
import {
  getCostByKmHeaders,
  getCostPerVehicleTypeHeaders
} from './RegionalCostsVehicleCostContent.data';

const RegionalCostsVehicleCostContent: React.FC<
  RegionalCostsVehicleCostContentProps
> = ({
  country,
  year,
  vehicleCost,
  vehicleTypes,
  vehicleTypeCosts,
  currency
}) => {
  const dispatch = useAppDispatch();
  const {localUnits} = useLocalUnitsContext();
  const {addToast} = useToast();

  const [panelState, setPanelState] = useState<VehicleCostsPanelState>({
    data: null,
    loading: false,
    error: null
  });

  const onUpdateCostsByKm = useCallback(
    (row: TableRow) => {
      const updatedCost = {...vehicleCost, ...row};
      dispatch(updateCountryCostEntry(updatedCost));
    },
    [vehicleCost, dispatch]
  );

  const getBaseCostsTableConfig = useCallback(
    (vehicleCost: RegionalCostsVehicleCost) => {
      const headers = getCostByKmHeaders(currency, localUnits);
      const row = headers.map(({key, fractionDigitsCount}) => {
        return {
          key,
          value: vehicleCost[key],
          displayValue: getScaledNumber(vehicleCost[key], fractionDigitsCount)
        };
      });
      const actions: TableWithPanelAction[] = [
        {
          type: 'panel',
          icon: IconEditSolid,
          panel: {
            title: 'Update country specific cost',
            onSuccess: onUpdateCostsByKm
          }
        }
      ];
      return {headers, rows: [row], actions};
    },
    [currency, localUnits, onUpdateCostsByKm]
  );

  const getCostsPerVehicleTypeTableConfig = useCallback(
    (currency: string) => {
      const {headers, rows} = getBaseCostsPerVehicleTypeTableConfig(
        vehicleTypes,
        vehicleTypeCosts,
        getCostPerVehicleTypeHeaders(currency, localUnits)
      );
      const actions: TableAction[] = [
        {
          icon: IconEditSolid,
          onClick: row => {
            setPanelState({
              data: row as Nullable<RegionalCostsBusTypeCost>,
              loading: false,
              error: null
            });
          }
        },
        {
          icon: IconDeleteSolid,
          appearance: 'danger',
          onClick: row => {
            confirm({
              PopupIcon: IconDelete,
              title: 'Delete this vehicle type cost?',
              message: ``
            }).then(confirmed => {
              if (confirmed) {
                dispatch(deleteCountryVehicleTypeCost(row.id, addToast));
              }
            });
          }
        }
      ];

      return {headers, rows, actions};
    },
    [vehicleTypes, vehicleTypeCosts, addToast, dispatch, localUnits]
  );

  const baseCosts = getBaseCostsTableConfig(vehicleCost);
  const costsPerVehicleType = getCostsPerVehicleTypeTableConfig(currency);

  return (
    <>
      <div className={styles.headerInfo}>
        <RegionalCostsExplanationNotice />
      </div>
      <div style={{width: '110%'}}>
        <section data-testid="base-costs">
          <TableWithPanel
            headers={baseCosts.headers}
            rows={baseCosts.rows}
            actions={baseCosts.actions}
          />
        </section>

        <section data-testid="costs-per-vehicle-type">
          <div className={styles.title}>
            <h3 className={styles.leftSide}>Costs per vehicle type</h3>
            <Button
              onClick={() => {
                setPanelState({
                  data: initVehicleTypeCost,
                  loading: false,
                  error: null
                });
              }}
            >
              <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
              Add costs per vehicle type
            </Button>
          </div>

          <Table
            headers={costsPerVehicleType.headers}
            rows={costsPerVehicleType.rows}
            actions={costsPerVehicleType.actions}
            caption={
              costsPerVehicleType.rows.length === 0 ? (
                <>No entries specified on country level.</>
              ) : undefined
            }
          />

          {/* Is used for both create and update logic */}
          <RegionalCostsVehicleCostPanel
            country={country}
            year={year}
            currency={currency}
            vehicleTypes={vehicleTypes}
            localUnits={localUnits}
            panelState={panelState}
            setPanelState={setPanelState}
          />
        </section>
      </div>
    </>
  );
};

export default React.memo(RegionalCostsVehicleCostContent);

import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {TotalDriverCostOverwriteProps} from './TotalDriverCostOverwrite.types';
import styles from './TotalDriverCostOverwrite.module.scss';

const TotalDriverCostOverwrite: React.FC<TotalDriverCostOverwriteProps> = ({
  value,
  entryId,
  currency,
  errors
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-total-driver-cost-overwrite`;

  const tooltipContent = useMemo(
    () => fieldInfoContent.totalDriverCostOverwrite,
    []
  );

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const totalDriverCostOverwrite =
        validateInputNumber(
          editedValue,
          value ? value.toString() : '',
          undefined,
          0
        ) ?? undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {totalDriverCostOverwrite}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="total-driver-cost-overwrite"
        label="Overwrite total driver cost*"
        inlineLabel={currency}
        value={formattedValue}
        tooltipContent={tooltipContent}
        isValid={!errors}
        infoError={errors?.optionality ?? errors?.nullable}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(TotalDriverCostOverwrite);

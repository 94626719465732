import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {DriverChangeHoursPerOperatingDayProps} from './DriverChangeHoursPerOperatingDay.types';
import styles from './DriverChangeHoursPerOperatingDay.module.scss';

const DriverChangeHoursPerOperatingDay: React.FC<
  DriverChangeHoursPerOperatingDayProps
> = ({value, entryId}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-driver-change-hours-per-operating-day`;

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const replacementHrPerDay =
        validateInputNumber(editedValue, value ? value.toString() : '') ??
        undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {replacementHrPerDay}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="driver-change-hours-per-operating-day"
        label={`Driver change/operating day`}
        value={formattedValue}
        tooltipContent={fieldInfoContent.driverChangeHrsOperatingDay}
        handleInputChange={handleInputChange}
        inlineLabel="Hrs"
      />
    </span>
  );
};

export default React.memo(DriverChangeHoursPerOperatingDay);

import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {SalaryChangeProps} from './SalaryChange.types';
import styles from './SalaryChange.module.scss';

const SalaryChange: React.FC<SalaryChangeProps> = ({
  value,
  entryId,
  currency
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-salary-change`;

  const tooltipContent = useMemo(() => fieldInfoContent.salaryChange, []);

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const salaryChange =
        validateInputNumber(
          editedValue,
          value ? value.toString() : '',
          undefined,
          undefined,
          true
        ) ?? undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {salaryChange}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="salary-change"
        label="Salary increase/decrease (all)"
        value={formattedValue}
        tooltipContent={tooltipContent}
        inlineLabel={`${currency}/h`}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(SalaryChange);

import React, {useState} from 'react';
import {Input, Tooltip} from '@flixbus/honeycomb-react';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import {TooltipInputProps} from './TooltipInput.types';

const TooltipInput: React.FC<TooltipInputProps> = ({
  id,
  name,
  label,
  value,
  inlineLabel,
  tooltipContent,
  disabled,
  isValid,
  infoError,
  handleInputChange
}) => {
  const [activeTooltip, setActiveTooltip] = useState(false);

  return (
    <Tooltip
      id={`tooltip-${id}`}
      content={tooltipContent}
      position="bottom"
      active={activeTooltip}
      onMouseOut={() => setActiveTooltip(false)}
    >
      <Input
        id={id}
        name={name}
        label={
          <LabelWithTooltip
            label={label}
            setActiveTooltip={() => setActiveTooltip(true)}
          />
        }
        inlineLabelLeft={inlineLabel}
        value={value}
        type="number"
        disabled={disabled}
        valid={isValid}
        infoError={infoError}
        onChange={e => handleInputChange(e.target.value)}
      />
    </Tooltip>
  );
};

export default React.memo(TooltipInput);

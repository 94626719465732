import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Button} from '@flixbus/honeycomb-react';
import {
  Icon,
  IconArrowLeft,
  IconClose,
  IconSaveSolid
} from '@flixbus/honeycomb-icons-react';
import {
  backToSecondDataImportScreen,
  resetProductionCostState
} from '../../store/slice';
import {releaseProductionCost} from '../../store/thunks';
import {useNavigate} from 'react-router-dom';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {resetDataTableEntries} from '../../../Home/store/slice';

const SchedulesFooter = () => {
  const dispatch = useDispatch();
  const calculationUserJourneyMode = useSelector(
    state => state?.productionCost.calculationUserJourneyMode
  );

  const navigate = useNavigate();

  const releaseAndResetDataTable = () => {
    dispatch(resetProductionCostState());
    dispatch(resetDataTableEntries());
    navigate('/');
  };

  const onClickSave = () => {
    dispatch(releaseProductionCost(releaseAndResetDataTable));
  };

  const onClickClose = () => {
    releaseAndResetDataTable();
  };

  return (
    <div className={`line-schedules-footer second`}>
      <Button onClick={() => dispatch(backToSecondDataImportScreen())}>
        <Icon InlineIcon={IconArrowLeft} aria-hidden="true" />
        Back
      </Button>
      <Button
        appearance="primary"
        onClick={
          calculationUserJourneyMode ===
          CalculationUserJourneyMode.EditCalculation
            ? onClickClose
            : onClickSave
        }
      >
        <Icon
          InlineIcon={
            calculationUserJourneyMode ===
            CalculationUserJourneyMode.EditCalculation
              ? IconClose
              : IconSaveSolid
          }
          aria-hidden="true"
        />

        {calculationUserJourneyMode ===
        CalculationUserJourneyMode.EditCalculation
          ? 'Close'
          : 'Save calculation'}
      </Button>
    </div>
  );
};

export default React.memo(SchedulesFooter);

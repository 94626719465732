import './wdyr'; // <--- first import
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import {EventType, AuthenticationResult} from '@azure/msal-browser';
import {msalInstance} from 'Pages/Auth/auth.config';
import {anonymizeUsername, logMajorVersionMismatch} from 'services/utils';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {initDataDog} from 'app/datadog/datadog.init';
import {AccountInfo} from '@azure/msal-common';
import formbricks from '@formbricks/js/website';
import {datadogRum} from '@datadog/browser-rum';

const {
  REACT_APP_TRACKING_FORMBRICKS_HOST: apiHost,
  REACT_APP_TRACKING_FORMBRICKS_ENV_ID: environmentId
} = process.env;

msalInstance.initialize().then(() => {
  // Configure active account
  const accounts = msalInstance.getAllAccounts();

  const setUserAccount = (account: AccountInfo) => {
    msalInstance.setActiveAccount(account);
    datadogRum.setUser({id: anonymizeUsername(account.username)});
  };

  if (accounts.length > 0) {
    setUserAccount(accounts[0]);
  }

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      setUserAccount(account);
    }
  });

  // Log major version mismatch of outdated packages
  logMajorVersionMismatch();

  // Formbricks integration
  if (typeof window !== 'undefined') {
    formbricks.init({
      apiHost: apiHost,
      environmentId: environmentId
    });
  }

  // Render app
  const container = document.getElementById('root') as HTMLElement;
  createRoot(container).render(
    <React.StrictMode>
      <Router>
        <App msalInstance={msalInstance} />
      </Router>
    </React.StrictMode>
  );

  initDataDog();

  // Measure performance
  reportWebVitals();
});

import styles from './index.module.scss';
import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';
import React, {
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  useCallback
} from 'react';
import {NavLink, Outlet} from 'react-router-dom';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {
  Grid,
  GridCol,
  Button,
  Select,
  NavHorizontal,
  PageContainer,
  Autocomplete,
  SelectOption,
  NavItem,
  Checkbox,
  Dropdown,
  AutocompleteOptions,
  AutocompleteInput,
  Divider,
  Fieldset
} from '@flixbus/honeycomb-react';
import {
  IconOfferSolid,
  IconDeleteSolid,
  IconDelete,
  Icon
} from '@flixbus/honeycomb-icons-react';
import ContentLoader from 'Pages/Common/ContentLoader';
import {confirm} from 'Pages/Common/Confirm';
import {useToast} from 'Pages/Common/toasts-container';
import {getPartners} from 'Pages/Partners/store/thunks';
import {setPartner} from 'Pages/Partners/store/slice';
import PartnerPanel from 'Pages/Partners/components/PartnerPanel';
import {VehicleCostsPanelState} from 'components/VehicleCosts/VehicleCostsPanel/VehicleCostsPanel.types';
import {
  addPartnerCostEntryYear,
  clonePartnerCostEntryYear,
  deletePartnerCostEntryYear,
  fetchBusPartnerAndCountryCostEntryYear,
  fetchPartnerCostYearsById
} from '../store/thunks';
import {
  selectBusPartnerCountry,
  selectPartner,
  selectYear
} from '../store/slice';
import navHorizontalOptions from 'Pages/PartnerCosts/constants/navHorizontalOptions';
import {useBusPartner} from '../hooks/useBusPartner';
import {useUpdateBusPartnerQualityCheck} from 'Pages/PartnerCosts/hooks/useUpdateBusPartnerQualityCheck';
import PartnerCostsVehicleCostPanel from 'Pages/PartnerCosts/components/PartnerCostsVehicleCostTab/PartnerCostsVehicleCostPanel/PartnerCostsVehicleCostPanel';
import {EPartnerCostsNewYearDataSource} from './index.types';
import {useLocalUnitsContext} from 'Pages/Common/LocalUnits';
import PowerBiReportLink from 'components/PowerBiReportLink/PowerBiReportLink';
import {
  POWER_BI_REPORT_BUS_PARTNER_COMPARE_URL,
  POWER_BI_REPORT_BUS_PARTNER_YOY_URL
} from 'constants/PowerBiReportUrls';

const PartnerCosts: React.FC = () => {
  const dispatch = useAppDispatch();
  const {addToast} = useToast();
  const {localUnits} = useLocalUnitsContext();
  const addNewYearButtonRef = useRef(null);
  const [partnerOptions, setPartnerOptions] = useState<any>([]);
  const [panelState, setPanelState] = useState<VehicleCostsPanelState>({
    data: null,
    loading: false,
    error: null
  });

  const partners = useAppSelector(({partners}) => partners.list);
  const partnerId = useAppSelector(({partnerCosts}) => partnerCosts.partnerId);
  const partnerCountry = useAppSelector(selectBusPartnerCountry);
  const year = useAppSelector(({partnerCosts}) => partnerCosts.year);
  const years = useAppSelector(({partnerCosts}) => partnerCosts.years);

  const partner = useBusPartner(partnerId);
  const vehicleTypes = useAppSelector(({vehicleTypes}) => vehicleTypes.list);
  const partnerCountryId = useAppSelector(
    ({partners}) => partners.list?.find(({id}) => id === partnerId)?.countryId
  );

  const qualityChecked = useAppSelector(
    ({partnerCosts}) => partnerCosts.qualityChecked
  );

  useEffect(() => {
    if (isNil(partners)) {
      dispatch(getPartners());
    }
  }, [partners, dispatch]);

  useEffect(() => {
    dispatch(
      fetchBusPartnerAndCountryCostEntryYear(partnerId, year, partnerCountryId)
    );
  }, [partnerId, year, partnerCountryId, dispatch]);

  const ignoreQualityCheckValidationConfirmation = useCallback(
    () =>
      confirm({
        title: 'Some mandatory fields are empty',
        message:
          'Please go back and specify values at either regional or partner level',
        acceptBtnText: 'Proceed anyway',
        cancelBtnText: 'Go back'
      }),
    []
  );

  const {updateQualityCheck} = useUpdateBusPartnerQualityCheck(
    ignoreQualityCheckValidationConfirmation
  );

  const onAddPartnerCostEntryYear = useCallback(
    (dataSource: EPartnerCostsNewYearDataSource) => {
      // This is a hack to trigger the dropdown to close
      if (addNewYearButtonRef.current) {
        (addNewYearButtonRef.current as HTMLButtonElement).click();
      }

      if (dataSource === EPartnerCostsNewYearDataSource.previousYear) {
        dispatch(clonePartnerCostEntryYear(partnerId, addToast));
      } else {
        dispatch(addPartnerCostEntryYear(partnerId, addToast));
      }
    },
    [partnerId, addToast, dispatch]
  );

  if (partners === null) {
    return <ContentLoader />;
  }

  const yearsOptions = years.map(year => (
    <SelectOption key={year} value={year}>
      {year}
    </SelectOption>
  ));

  const handleDebounce = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
    const options = partners
      .reduce((acc: any[], curr) => {
        if (curr.name.toLowerCase().includes(value.toLowerCase())) {
          return [
            ...acc,
            {
              value: curr.id,
              title: curr.name
            }
          ];
        } else {
          return acc;
        }
      }, [])
      .sort((a, b) => a.title.localeCompare(b.title));

    setPartnerOptions(options);
  };

  const handleSelect = (option: any) => {
    dispatch(selectPartner(option.value));
    dispatch(fetchPartnerCostYearsById(option.value));
    setPartnerOptions([]);
  };

  const partnerName = partnerId
    ? partners.find(item => item.id === partnerId)?.name ?? ''
    : '';

  const onDeletePartnerCostEntryYear = async () => {
    if (
      await confirm({
        PopupIcon: IconDelete,
        title: `You are about to delete all cost data for partner ${partnerName} and year ${year}`,
        message: `Do you wish to proceed ?`
      })
    ) {
      dispatch(deletePartnerCostEntryYear(year, addToast));
    }
  };

  return (
    <PageContainer>
      <div className={`header-section`}>
        <Grid>
          <GridCol size={3}>
            <div className={`partner-costs-partners`}>
              <div>
                <Autocomplete
                  options={partnerOptions}
                  value={partnerName}
                  onDebounce={handleDebounce}
                  onSelect={handleSelect}
                >
                  <AutocompleteInput
                    id="partner-costs-select-partner"
                    label="Partner"
                  />
                  <AutocompleteOptions label="Partner" optionsToDisplay={5} />
                </Autocomplete>
              </div>
              <div>
                <Button
                  display="square"
                  aria-label="New partner"
                  onClick={() => dispatch(setPartner(null))}
                >
                  <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
                </Button>
              </div>
            </div>
          </GridCol>
          <GridCol size={3}>
            <div className={`partner-costs-years`}>
              <div>
                <Select
                  disabled={isNil(partnerId)}
                  id="partner-costs-years"
                  label="Calendar year"
                  value={year === null ? '' : year}
                  placeholder="Select a year"
                  onChange={e => dispatch(selectYear(Number(e.target.value)))}
                >
                  {yearsOptions}
                </Select>
              </div>
              <div className={styles.yearActions}>
                <Dropdown
                  links={[
                    {
                      text: 'Copy from previous year',
                      onClick: () =>
                        onAddPartnerCostEntryYear(
                          EPartnerCostsNewYearDataSource.previousYear
                        ),
                      role: 'button',
                      href: '#'
                    },
                    {
                      text: 'Start with blank values',
                      onClick: () =>
                        onAddPartnerCostEntryYear(
                          EPartnerCostsNewYearDataSource.blankValues
                        ),
                      role: 'button',
                      href: '#'
                    }
                  ]}
                >
                  <Button
                    display="square"
                    aria-label="New year"
                    disabled={isNil(partnerId)}
                    innerRef={addNewYearButtonRef}
                  >
                    <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
                  </Button>
                </Dropdown>
                <Button
                  display="square"
                  aria-label="Delete year"
                  disabled={isNil(partnerId) || isNilOrEmpty(year)}
                  onClick={onDeletePartnerCostEntryYear}
                >
                  <Icon InlineIcon={IconDeleteSolid} aria-hidden="true" />
                </Button>
              </div>
            </div>
          </GridCol>
        </Grid>

        <div className={`hcr-space-4-top`}>
          <Grid align="center" extraClasses={year ? '' : 'hidden'}>
            <GridCol key="grid-nested-checkbox" size={4}>
              <Checkbox
                id="partner-costs-year-quality-check"
                checked={qualityChecked}
                value={qualityChecked.toString()}
                aria-label="partner-costs-year-quality-check"
                label={year + ` partner costs database completed`}
                onChange={event => updateQualityCheck(event.target.checked)}
              />
            </GridCol>
            <GridCol
              key="grid-nested-link"
              size={8}
              extraClasses={'justify-end'}
            >
              <Fieldset itemSpacing="6" horizontal>
                <PowerBiReportLink
                  href={POWER_BI_REPORT_BUS_PARTNER_YOY_URL}
                  label="Analyse YoY development"
                />
                <PowerBiReportLink
                  href={POWER_BI_REPORT_BUS_PARTNER_COMPARE_URL}
                  label="Compare to other BPs"
                />
              </Fieldset>
            </GridCol>
          </Grid>
        </div>

        <Grid>
          <GridCol size={12}>
            <div className={`hcr-space-4-top nav-horizontal-container`}>
              <NavHorizontal
                extraClasses={`partner-costs-nav`}
                aria-label="Partner Costs Navigation"
              >
                {navHorizontalOptions.map(item => (
                  <NavItem
                    key={item.path}
                    to={item.path}
                    RouterLink={NavLink}
                    end
                  >
                    {item.name}
                  </NavItem>
                ))}
              </NavHorizontal>
            </div>
          </GridCol>
        </Grid>
      </div>

      <Divider extraClasses={'full-width-divider'} />

      <div className="hcr-space-4-top">
        <Outlet />
      </div>

      <PartnerPanel />
      {partnerCountry && vehicleTypes && (
        <PartnerCostsVehicleCostPanel
          partnerId={partnerId}
          year={year}
          currency={partner?.currency ?? ''}
          vehicleTypes={vehicleTypes}
          localUnits={localUnits}
          panelState={panelState}
          setPanelState={setPanelState}
          partnerCountry={partnerCountry}
        />
      )}
    </PageContainer>
  );
};

export default React.memo(PartnerCosts);

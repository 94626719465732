import {gql} from '@apollo/client';

const GET_BUS_PARTNER_AND_COUNTRY_COST_ENTRY_YEAR = gql`
  query getBusPartnerAndCountryCostEntryYear(
    $busPartnerId: ID!
    $year: Int!
    $countryId: ID!
  ) {
    getBusPartnerCostEntryYear(busPartnerId: $busPartnerId, year: $year) {
      year
      qualityChecked
      qualityCheckedLastEditedAt
      busCostEntry {
        adblueCost
        dieselCost
        lngCost
        tiresCost
        currency
        year
      }
      busTypeCostEntries {
        id
        year
        busTypeId
        leasingCost
        interestCost
        insuranceCost
        motorVehicleTax
        maintenanceCostPerMonth
        maintenanceCostPerDistanceUnit
        customIdentifier
        vehicleProductionYear
      }
      driverCostEntry {
        year
        daytimeWeekdaysWorkingHourCost
        daytimeWeekendsWorkingHourCost
        nighttimeWeekdaysWorkingHourCost
        nighttimeWeekendsWorkingHourCost
        replacementCostPerKm
        replacementCostPerHr
        accommodationCostPerNight
      }
      otherCostEntry {
        year
        overheadCost
        legalCost
        cleaningCost
        repairCostPerDistanceUnit
        repairCostPerMonth
        otherCost
      }
    }

    countryCostEntry(countryId: $countryId, year: $year) {
      year
      busCostEntry {
        adblueCost
        flixStandardCost
        dieselCost
        lngCost
        tiresCost
        currency
        year
      }
      busTypeCostEntries {
        id
        year
        busTypeId
        leasingCost
        interestCost
        insuranceCost
        motorVehicleTax
        maintenanceCostPerMonth
        maintenanceCostPerDistanceUnit
        customIdentifier
        vehicleProductionYear
      }
      otherCostEntry {
        year
        overheadCost
        legalCost
        cleaningCost
        repairCostPerDistanceUnit
        repairCostPerMonth
        otherCost
      }

      driverCostEntry {
        year
        daytimeWeekdaysWorkingHourCost
        daytimeWeekendsWorkingHourCost
        nighttimeWeekdaysWorkingHourCost
        nighttimeWeekendsWorkingHourCost
        replacementCostPerKm
        replacementCostPerHr
        accommodationCostPerNight
      }

      qualityChecked
    }
  }
`;

export default GET_BUS_PARTNER_AND_COUNTRY_COST_ENTRY_YEAR;

import React from 'react';
import {useSelector} from 'react-redux';
import ProductionCostHeader from '../ProductionCostHeader';
import {ProductionCostHeaderStepOption} from '../ProductionCostHeader/ProductionCostHeaderSteps/ProductionCostHeaderSteps.types';
import SchedulesFooter from './SchedulesFooter';
import CalculatedProductionCosts from './CalculatedProductionCosts';
import styles from './index.module.scss';

const ProductionCostStepThree = () => {
  const calculationUserJourneyMode = useSelector(
    ({productionCost}) => productionCost.calculationUserJourneyMode
  );

  return (
    <>
      <div className={styles.header}>
        <ProductionCostHeader
          mode={calculationUserJourneyMode}
          step={ProductionCostHeaderStepOption.Summary}
        />
      </div>

      <CalculatedProductionCosts />
      <SchedulesFooter />
    </>
  );
};

export default React.memo(ProductionCostStepThree);

import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';

import {
  Button,
  Grid,
  GridCol,
  Input,
  Select,
  SelectOption
} from '@flixbus/honeycomb-react';
import {Icon, IconOfferSolid, IconDelete} from '@flixbus/honeycomb-icons-react';
import {isEmpty, isNil} from 'ramda';
import {
  addPartnerDepot,
  deletePartnerDepot,
  resetPartner,
  setPartner,
  updatePartnerDepot
} from '../store/slice';
import {savePartner} from '../store/thunks';
import ContentLoader from '../../Common/ContentLoader';
import {confirm} from '../../Common/Confirm';
import {isBlank, isNotNilOrEmpty} from 'ramda-adjunct';
import CpcPanel from '../../Common/CpcPanel';
import {getCountries} from '../../Countries/store/thunks';

const PartnerPanel = ({
  partner,
  countries,
  setPartner,
  resetPartner,
  savePartner,
  addPartnerDepot,
  updatePartnerDepot,
  deletePartnerDepot
}) => {
  const dispatch = useDispatch();
  const [showPanel, setShowPanel] = useState(false);
  const [nameIsValid, setNameIsValid] = useState(null);
  const [countryIdIsValid, setCountryIdIsValid] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [depotsAreValid, setDepotsAreValid] = useState([]);
  const isEditPartner = isNotNilOrEmpty(partner?.id);

  useEffect(() => {
    setShowPanel(!isNil(partner));
  }, [partner]);

  useEffect(() => {
    if (isNil(countries)) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  const closePanel = () => {
    setShowPanel(false);
    resetValidationState();
    resetPartner();
  };

  const resetValidationState = () => {
    setNameIsValid(null);
    setCountryIdIsValid(null);
    setErrorMessage(null);
    setDepotsAreValid([]);
  };

  const handleSavePartner = () => {
    resetValidationState();
    let formIsValid = true;

    if (isBlank(partner.name)) {
      setNameIsValid(false);
      formIsValid = false;
    }

    if (isBlank(partner.countryId)) {
      setCountryIdIsValid(false);
      formIsValid = false;
    }

    partner.depots.forEach((depot, index) => {
      depotsAreValid[index] = depot.city !== '';
      setDepotsAreValid(depotsAreValid);

      if (isBlank(depot.city)) {
        formIsValid = false;
      }
    });

    if (formIsValid) {
      savePartner(setErrorMessage);
    }
  };

  const getCountriesOptions = () =>
    countries.map(country => (
      <SelectOption key={country.id} value={country.id}>
        {country.name}
      </SelectOption>
    ));

  const deletePartnerDepotAction = async index => {
    if (
      await confirm({
        PopupIcon: IconDelete,
        title: 'Delete this depot?',
        message: ``
      })
    ) {
      deletePartnerDepot(index);
    }
  };

  return (
    <CpcPanel
      title={isEditPartner ? 'Edit partner' : 'Add partner'}
      activeAction={showPanel}
      closeAction={closePanel}
      saveAction={handleSavePartner}
    >
      {!isNil(partner) && !isNil(countries) ? (
        <>
          <Input
            name="partner-backend-id"
            id="artner-backend-id"
            label="PlanR Backend ID"
            value={partner.backendId || ''}
            valid={errorMessage === null ? null : false}
            infoError={errorMessage}
            onChange={e =>
              setPartner({
                ...partner,
                backendId: isBlank(e.target.value) ? null : e.target.value
              })
            }
          />
          <div className={`hcr-space-4-top`}>
            <Input
              name="partner-name"
              id="partner-name"
              label="Partner name"
              valid={nameIsValid}
              value={partner.name}
              onChange={e => setPartner({...partner, name: e.target.value})}
            />
          </div>

          <div className={`hcr-space-4-top`}>
            <Select
              id="partner-country"
              label="Country"
              placeholder="Select a Country"
              value={partner.countryId}
              valid={countryIdIsValid}
              onChange={e =>
                setPartner({...partner, countryId: e.target.value})
              }
            >
              {getCountriesOptions()}
            </Select>
          </div>

          {partner.depots.map((depot, index) => {
            return (
              <div
                key={`partner-depot-${index}`}
                className={`hcr-space-4-top`}
                style={{borderTop: '1px solid #E1E1E1'}}
              >
                <div className={`partner-depot-header hcr-space-2-top`}>
                  <div>Depot {index + 1}</div>
                  <div>
                    <Button
                      display="square"
                      aria-label="Delete Partner Depot"
                      link
                      onClick={() => deletePartnerDepotAction(index)}
                    >
                      <Icon InlineIcon={IconDelete} aria-hidden="true" />
                    </Button>
                  </div>
                </div>
                <Grid>
                  <GridCol size={12}>
                    <div className={`hcr-space-2-top`}>
                      <Input
                        name="partner-depot-street"
                        id="partner-depot-street"
                        label="Street + No."
                        value={depot.street}
                        onChange={e =>
                          updatePartnerDepot(index, {
                            ...depot,
                            street: e.target.value
                          })
                        }
                      />
                    </div>
                  </GridCol>
                </Grid>
                <Grid>
                  <GridCol size={6}>
                    <div className={`hcr-space-4-top`}>
                      <Input
                        name="partner-depot-zip-code"
                        id="partner-depot-zip-code"
                        label="ZIP code"
                        value={depot.postCode}
                        onChange={e =>
                          updatePartnerDepot(index, {
                            ...depot,
                            postCode: e.target.value
                          })
                        }
                      />
                    </div>
                  </GridCol>
                  <GridCol size={6}>
                    <div className={`hcr-space-4-top`}>
                      <Input
                        name="partner-depot-city"
                        id="partner-depot-city"
                        label="City"
                        value={depot.city}
                        valid={
                          isEmpty(depotsAreValid) ? null : depotsAreValid[index]
                        }
                        onChange={e =>
                          updatePartnerDepot(index, {
                            ...depot,
                            city: e.target.value
                          })
                        }
                      />
                    </div>
                  </GridCol>
                </Grid>
              </div>
            );
          })}

          <div className={`hcr-space-4-top`}>
            <Button onClick={() => addPartnerDepot()}>
              <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
              Add depot
            </Button>
          </div>
        </>
      ) : (
        <ContentLoader />
      )}
    </CpcPanel>
  );
};

export default connect(
  state => ({
    partner: state.partners.partner,
    countries: state.countries.list
  }),
  {
    setPartner,
    resetPartner,
    savePartner,
    addPartnerDepot,
    updatePartnerDepot,
    deletePartnerDepot
  }
)(PartnerPanel);

import {gql} from '@apollo/client';

const UPDATE_COUNTRY_BUS_TYPE_COST = gql`
  mutation updateCountryBusTypeCostEntry(
    $id: ID!
    $busTypeId: ID!
    $customIdentifier: String
    $insuranceCost: Float
    $interestCost: Float
    $leasingCost: Float
    $maintenanceCostPerMonth: Float
    $maintenanceCostPerDistanceUnit: CostPerDistance
    $motorVehicleTax: Float
    $vehicleProductionYear: Int
  ) {
    updateCountryBusTypeCostEntry(
      input: {
        id: $id
        busTypeId: $busTypeId
        customIdentifier: $customIdentifier
        insuranceCost: $insuranceCost
        interestCost: $interestCost
        leasingCost: $leasingCost
        maintenanceCostPerMonth: $maintenanceCostPerMonth
        maintenanceCostPerDistanceUnit: $maintenanceCostPerDistanceUnit
        motorVehicleTax: $motorVehicleTax
        vehicleProductionYear: $vehicleProductionYear
      }
    ) {
      id
      busTypeId
      year
      currency
      customIdentifier
      insuranceCost
      interestCost
      leasingCost
      maintenanceCostPerMonth
      maintenanceCostPerDistanceUnit
      motorVehicleTax
      vehicleProductionYear
    }
  }
`;

export default UPDATE_COUNTRY_BUS_TYPE_COST;

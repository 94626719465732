import {gql} from '@apollo/client';

const GET_COUNTRY_CURRENCIES = gql`
  query {
    countries {
      id
      name
      businessRegion
      currency
      repairCostDimension
      maintenanceCostDimension
    }
  }
`;

export default GET_COUNTRY_CURRENCIES;

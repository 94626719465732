import {gql} from '@apollo/client';

const CREATE_PARTNER_VEHICLE_TYPE_COST = gql`
  mutation createBusPartnerBusTypeCostEntry(
    $partnerId: ID!
    $busTypeId: ID!
    $year: Int!
    $leasingCost: Float
    $insuranceCost: Float
    $interestCost: Float
    $motorVehicleTax: Float
    $maintenanceCostPerMonth: Float
    $maintenanceCostPerDistanceUnit: CostPerDistance
    $customIdentifier: String
    $vehicleProductionYear: Int
  ) {
    createBusPartnerBusTypeCostEntry(
      input: {
        busPartnerId: $partnerId
        busTypeId: $busTypeId
        year: $year
        leasingCost: $leasingCost
        insuranceCost: $insuranceCost
        interestCost: $interestCost
        motorVehicleTax: $motorVehicleTax
        maintenanceCostPerMonth: $maintenanceCostPerMonth
        maintenanceCostPerDistanceUnit: $maintenanceCostPerDistanceUnit
        customIdentifier: $customIdentifier
        vehicleProductionYear: $vehicleProductionYear
      }
    ) {
      id
      busTypeId
      year
      leasingCost
      insuranceCost
      interestCost
      motorVehicleTax
      maintenanceCostPerMonth
      maintenanceCostPerDistanceUnit
      customIdentifier
      vehicleProductionYear
    }
  }
`;

export default CREATE_PARTNER_VEHICLE_TYPE_COST;

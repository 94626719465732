import React, {useCallback} from 'react';
import {Button} from '@flixbus/honeycomb-react';
import {Icon} from '@flixbus/honeycomb-icons-react';
import {
  ProductionCostFooterButton,
  ProductionCostFooterProps
} from './ProductionCostFooter.types';
import styles from './ProductionCostFooter.module.scss';

const ProductionCostFooter: React.FC<ProductionCostFooterProps> = ({
  leftSideButtons,
  rightSideButtons
}) => {
  const buildIcon = useCallback(
    (button: ProductionCostFooterButton) => (
      <Button
        onClick={button.onClick}
        key={button.label}
        appearance={button.appearance}
        disabled={button.disabled}
      >
        {button.leftSideIcon && (
          <Icon
            InlineIcon={button.leftSideIcon}
            aria-label={`Left Side Icon`}
          />
        )}

        {button.label}

        {button.rightSideIcon && (
          <Icon
            InlineIcon={button.rightSideIcon}
            aria-label={`Right Side Icon`}
          />
        )}
      </Button>
    ),
    []
  );

  return (
    <div className={styles.container}>
      <span className={styles.leftSide}>{leftSideButtons?.map(buildIcon)}</span>

      <span className={styles.rightSide}>
        {rightSideButtons?.map(buildIcon)}
      </span>
    </div>
  );
};

export default React.memo(ProductionCostFooter);

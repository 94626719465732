import {
  formatCostPerDistanceFieldName,
  formatCostPerVolumeFieldName,
  formatCostPerWeightFieldName
} from '../../../Common/LabelUtils';

export const inputCostFactorsViewHeaders = localUnits => [
  {
    key: 'scheduleName',
    name: 'Schedule Name'
  },
  {
    key: 'startDate',
    name: 'Start Date'
  },
  {
    key: 'endDate',
    name: 'End Date'
  },
  {
    key: 'busName',
    name: 'Vehicle ID'
  },
  {
    key: 'busTypeCostEntryId',
    name: 'Vehicle Type'
  },
  {
    key: 'busTypeCustomIdentifier',
    name: 'Custom Identifier'
  },
  {
    key: 'vehicleProductionYear',
    name: 'Vehicle Production Year'
  },
  {
    key: 'country',
    name: 'Country Rules'
  },
  {
    key: 'timeZone',
    name: 'Time Zone'
  },
  {
    key: 'countryFlixStandardCost',
    name: 'Country Flix Standard Cost (per bus per month)'
  },
  {
    key: 'countryDriverConceptMultiplicationFactor',
    name: 'Country Driver Concept Multiplication Factor'
  },
  {
    key: 'countryDriverConceptDailyAllowance',
    name: 'Country Driver Concept Daily Allowance'
  },
  {
    key: 'dieselCost',
    name: formatCostPerVolumeFieldName(localUnits, 'Diesel Cost')
  },
  {
    key: 'adblueCost',
    name: formatCostPerVolumeFieldName(localUnits, 'Adblue Cost')
  },
  {
    key: 'lngCost',
    name: formatCostPerWeightFieldName(localUnits, 'LNG Cost')
  },
  {
    key: 'costPerTire',
    name: formatCostPerDistanceFieldName(localUnits, 'Cost Per Tire')
  },
  {
    key: 'leasingCost',
    name: 'Leasing/amortisation Cost (per month)'
  },
  {
    key: 'insuranceCost',
    name: 'Insurance Cost (per month)'
  },
  {
    key: 'interestCost',
    name: 'Interest Cost (per month)'
  },
  {
    key: 'motorVehicleTax',
    name: 'Motor Vehicle Tax (per month)'
  },
  {
    key: 'maintenanceCostPerMonth',
    name: 'Maintenance Cost (per month)'
  },
  {
    key: 'maintenanceCostPerDistanceUnit',
    name: formatCostPerDistanceFieldName(localUnits, 'Maintenance Cost')
  },
  {
    key: 'driverDaytimeWeekdaysWorkingHourCost',
    name: 'Driver Daytime/weekdays (per hr)'
  },
  {
    key: 'driverNighttimeWeekdaysWorkingHourCost',
    name: 'Driver Nighttime/weekdays (per hr)'
  },
  {
    key: 'driverDaytimeWeekendsWorkingHourCost',
    name: 'Driver Daytime/weekend (per hr)'
  },
  {
    key: 'driverNighttimeWeekendsWorkingHourCost',
    name: 'Driver Nighttime/weekend (per hr)'
  },
  {
    key: 'driverReplacementCostPerKm',
    name: formatCostPerDistanceFieldName(localUnits, 'Driver Change Cost')
  },
  {
    key: 'driverReplacementCostPerHr',
    name: 'Driver Change Cost (per hr)'
  },
  {
    key: 'accommodationCostPerNight',
    name: 'Driver Accommodation Cost (per night)'
  },
  {
    key: 'overheadCost',
    name: 'Overhead Cost (per bus per month)'
  },
  {
    key: 'legalCost',
    name: 'Legal Cost (per bus per month)'
  },
  {
    key: 'cleaningCost',
    name: 'Cleaning Cost (per bus per day)'
  },
  {
    key: 'repairCostPerMonth',
    name: 'Repair Cost (per bus per month)'
  },
  {
    key: 'repairCostPerDistanceUnit',
    name: `Repair Cost (per bus per ${localUnits.distanceAbbreviation})`
  },
  {
    key: 'otherCost',
    name: 'Other Cost (per bus per month)'
  },
  {
    key: 'dieselConsumption',
    name: `Diesel Consumption (${localUnits.dieselConsumption})`
  },
  {
    key: 'adblueConsumption',
    name: `Adblue Consumption (${localUnits.adBlueConsumption})`
  },
  {
    key: 'lngConsumption',
    name: `LNG Consumption (${localUnits.lngConsumption})`
  },
  {
    key: 'numberOfTires',
    name: 'Number of Tires'
  },
  {
    key: 'emptyKmPerDay',
    name: `Empty ${localUnits.distanceAbbreviation} Per Day`
  },
  {
    key: 'emptyHrPerDay',
    name: 'Empty Hr Per Day'
  },
  {
    key: 'salaryChange',
    name: 'Salary Change'
  },
  {
    key: 'accommodationsPerWeek',
    name: 'Accommodations Per Week'
  },
  {
    key: 'replacementKmPerDay',
    name: `Replacement ${localUnits.distanceAbbreviation} Per Day`
  },
  {
    key: 'replacementHrPerDay',
    name: 'Replacement Hr Per Day'
  },
  {
    key: 'additionalCostPerTrip',
    name: 'Additional Cost Per Trip'
  },
  {
    key: 'totalDriverCostOverwrite',
    name: 'Total Driver Cost Overwrite'
  },
  {
    key: 'numberOfDrivers',
    name: 'Number of Drivers'
  },
  {
    key: 'driverSalary',
    name: 'Driver Salary'
  },
  {
    key: 'busUtilizationFactor',
    name: 'Vehicle Utilization Factor (%)'
  },
  {
    key: 'flixStandardCostOverwrite',
    name: 'Flix Standard Cost Overwrite Per Month'
  }
];

import React, {useMemo, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {
  Grid,
  GridCol,
  Button,
  DataTable,
  DataTableHead,
  DataTableRow,
  Fieldset,
  PageContainer
} from '@flixbus/honeycomb-react';
import {
  IconOfferSolid,
  IconEditSolid,
  IconDeleteSolid,
  IconDelete,
  Icon
} from '@flixbus/honeycomb-icons-react';
import ContentLoader from '../../Common/ContentLoader';
import {isNil} from 'ramda';
import {confirm} from '../../Common/Confirm';
import {getVehicleTypes, deleteVehicleType} from '../store/thunks';
import {setVehicleType} from '../store/slice';
import VehicleTypePanel from './VehicleTypePanel';
import {useToast} from '../../Common/toasts-container';
import {useLocalUnitsContext} from '../../Common/LocalUnits';
import {getScaledNumber} from '../../Common/Utils';
import {VehicleFuelType} from '../store/types';

const VehicleTypesPage = ({
  vehicleTypes,
  getVehicleTypes,
  setVehicleType,
  deleteVehicleType
}) => {
  const {addToast} = useToast();
  const {localUnits} = useLocalUnitsContext();
  useEffect(() => {
    if (isNil(vehicleTypes)) {
      getVehicleTypes();
    }
  }, [vehicleTypes, getVehicleTypes]);

  const deleteVehicleTypeAction = useCallback(
    async vehicleType => {
      if (
        await confirm({
          PopupIcon: IconDelete,
          title: 'Delete this vehicle type?',
          message: `${vehicleType.name}`
        })
      ) {
        deleteVehicleType(vehicleType, addToast);
      }
    },
    [deleteVehicleType, addToast]
  );

  const tableHeaders = useMemo(
    () => (
      <DataTableHead>
        <th scope="col">Manufacturer & Model</th>
        <th scope="col">Fuel type</th>
        <th scope="col">
          Diesel Consumption
          <br /> ({localUnits.dieselConsumption})
        </th>
        <th scope="col">
          Adblue Consumption
          <br /> ({localUnits.adBlueConsumption})
        </th>
        <th scope="col">
          LNG Consumption
          <br /> ({localUnits.lngConsumption})
        </th>
        <th scope="col">SD/DD</th>
        <th scope="col">No. of tires</th>
        <th scope="col"></th>
      </DataTableHead>
    ),
    [localUnits]
  );

  const tableRows = useMemo(
    () =>
      [...(vehicleTypes ?? [])]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(vehicleType => ({
          cells: [
            vehicleType.name,
            vehicleType.fuelType === VehicleFuelType.DIESEL ? 'Diesel' : 'LNG',
            getScaledNumber(vehicleType.dieselConsumption),
            getScaledNumber(vehicleType.adblueConsumption),
            getScaledNumber(vehicleType.lngConsumption),
            vehicleType.doubleDecker ? 'DD' : 'SD',
            vehicleType.numberOfTires,
            <Fieldset key={vehicleType.id} horizontal>
              <div style={{width: 'max-content'}}>
                <Button
                  display="square"
                  aria-label="Edit Vehicle Type"
                  onClick={() => setVehicleType(vehicleType)}
                >
                  <Icon InlineIcon={IconEditSolid} aria-hidden="true" />
                </Button>
                <Button
                  display="square"
                  aria-label="Delete Vehicle Type"
                  appearance={`danger`}
                  onClick={() => deleteVehicleTypeAction(vehicleType)}
                >
                  <Icon InlineIcon={IconDeleteSolid} aria-hidden="true" />
                </Button>
              </div>
            </Fieldset>
          ]
        })),
    [deleteVehicleTypeAction, vehicleTypes, setVehicleType]
  );

  if (isNil(vehicleTypes)) {
    return <ContentLoader />;
  }

  return (
    <PageContainer>
      <div className={`hcr-has-text-right hcr-space-4-top hcr-space-2-bottom`}>
        <Button onClick={() => setVehicleType()}>
          <Icon InlineIcon={IconOfferSolid} aria-hidden="true" />
          Add vehicle type
        </Button>
      </div>
      <Grid>
        <GridCol size={12}>
          <DataTable id={`vehicle-types-table`} headers={tableHeaders}>
            {tableRows.map((row, rowIndex) => (
              <DataTableRow key={rowIndex}>
                {row.cells.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </DataTableRow>
            ))}
          </DataTable>
        </GridCol>
      </Grid>
      <VehicleTypePanel />
    </PageContainer>
  );
};

export default connect(
  state => ({
    vehicleTypes: state.vehicleTypes.list
  }),
  {getVehicleTypes, setVehicleType, deleteVehicleType}
)(VehicleTypesPage);

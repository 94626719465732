import React, {useCallback, useMemo} from 'react';
import {Button, Popup, PopupSection, Text} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {Icon, IconNewTab} from '@flixbus/honeycomb-icons-react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {resetInvalidCostFactorsErrors} from 'Pages/ProductionCost/store/slice';
import styles from './InvalidCostFactorsPopup.module.scss';

const InvalidCostFactorsPopup: React.FC = () => {
  const costYear = useAppSelector(
    ({productionCost}) => productionCost.costYear
  );
  const invalidCostFactors = useAppSelector(
    ({productionCost}) =>
      productionCost.serverErrors.calculateProductionCost?.invalidCostFactors
  );
  const dispatch = useAppDispatch();

  const errorsList = useMemo(() => {
    const errorsSet = new Set<string>();
    for (const invalidCostFactor of invalidCostFactors ?? []) {
      errorsSet.add(invalidCostFactor.errorMessage);
    }
    return Array.from(errorsSet);
  }, [invalidCostFactors]);

  const goToCostFactorsDB = useCallback(
    () => window.open('/partner-costs', '_blank'),
    []
  );

  const closePopup = useCallback(
    () => dispatch(resetInvalidCostFactorsErrors()),
    [dispatch]
  );

  return (
    <Popup
      aria-labelledby="invalid-cost-factors-popup"
      active={errorsList && errorsList.length > 0}
    >
      <PopupSection type="title">
        <Text Elem="div">
          <h3>Missing data in cost factors database</h3>
        </Text>
      </PopupSection>

      <PopupSection type="content">
        <Text extraClasses="grayscale-70-color">
          There is data missing in the cost factor database and we can&apos;t
          make a calculation without that data. Please fill out the data for{' '}
          {costYear} for your partner or related country, or choose a different
          partner.
        </Text>

        <ul>
          {errorsList?.map((error, i) => (
            <li key={i} className={styles.error}>
              {error}
            </li>
          ))}
        </ul>
      </PopupSection>

      <PopupSection type="actions">
        <Button
          onClick={goToCostFactorsDB}
          key="goToCostFactorsDB"
          appearance="secondary"
        >
          Edit cost factors in DB
          <Icon InlineIcon={IconNewTab} />
        </Button>

        <Button onClick={closePopup} key="closePopup">
          Close and try again
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default React.memo(InvalidCostFactorsPopup);

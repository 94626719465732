import {useMsal} from '@azure/msal-react';
import {
  Button,
  Heading,
  ImageBox,
  PageContainer,
  Text,
  Link
} from '@flixbus/honeycomb-react';
import {Icon, IconNewTab} from '@flixbus/honeycomb-icons-react';
import React from 'react';

import {apiAccessRequest} from 'Pages/Auth/auth.config';

import welcomeImage from './welcome.png';

import styles from './Login.module.scss';

const appTitle = process.env.REACT_APP_TITLE;

export const Login = () => {
  const {instance} = useMsal();
  const handleLogin = async () =>
    await instance.loginRedirect(apiAccessRequest);

  const trainingMaterialLink =
    'https://einfachbusfahren.sharepoint.com/sites/BusinessDevelopment/SitePages/Commercial-Partner-Cockpit---CPC.aspx';

  const trainingMaterialLinkTag = (
    <Link target="_blank" href={trainingMaterialLink} className="nav-link">
      {' '}
      CPC Guidelines & Knowledge{' '}
      <Icon
        style={{fill: 'currentColor'}}
        InlineIcon={IconNewTab}
        aria-label="Opens in a New Tab"
      />
    </Link>
  );

  const textMessage = (
    <>
      <Heading size={1}>Welcome to {appTitle}</Heading>
      <Text>
        Please log in via the button below. Find all relevant information on
        Commercial Partner Cockpit (including training material) here:
        {trainingMaterialLinkTag}
      </Text>
      <div className={styles.buttonContainer}>
        <Button appearance="secondary" onClick={handleLogin}>
          Login to {appTitle}
        </Button>
      </div>
    </>
  );

  return (
    <div className={styles.container}>
      <PageContainer>
        <ImageBox
          img={{
            src: welcomeImage,
            alt: 'cpclogin'
          }}
          extraClasses={styles.imageLink}
        >
          {textMessage}
        </ImageBox>
      </PageContainer>
    </div>
  );
};

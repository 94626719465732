import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {MsalProvider} from '@azure/msal-react';
import {IPublicClientApplication} from '@azure/msal-browser';
import {ApolloProvider} from '@apollo/client';
import {MainWrapper, ThemeWrapper} from '@flixbus/honeycomb-react';
import {ToastsProvider} from 'Pages/Common/toasts-container';
import {LocalUnitsProvider} from 'Pages/Common/LocalUnits';
import graphqlClient from 'services/graphqlClient';
import AppRoutes from './AppRoutes';
import store from './store';
import '@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';
import './App.scss';

const App: React.FC<{msalInstance: IPublicClientApplication}> = ({
  msalInstance
}) => (
  <MsalProvider instance={msalInstance}>
    <ReduxProvider store={store}>
      <ApolloProvider client={graphqlClient}>
        <ThemeWrapper theme="default" className="full-height">
          <MainWrapper extraClasses="full-height">
            <LocalUnitsProvider>
              <ToastsProvider>
                <AppRoutes />
              </ToastsProvider>
            </LocalUnitsProvider>
          </MainWrapper>
        </ThemeWrapper>
      </ApolloProvider>
    </ReduxProvider>
  </MsalProvider>
);

export default App;
